<template>
  <GenericListButton :disabled="disabled" @click="onClick">
    <img class="icon" :src="imgSrc" :title="label" />
  </GenericListButton>
</template>

<script>
import GenericListButton from '@/components/shared/lists/GenericListButton.vue'

export default {
  components: { GenericListButton },
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
