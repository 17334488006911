<template>
  <button class="button list-button" :disabled="disabled" @click="onClick()">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.list-button {
  flex: none;
  vertical-align: middle;
  opacity: 0.85;
  padding: 8px;

  &:disabled {
    opacity: 0.4;
  }

  & .icon {
    width: 20px;
    height: 20px;
  }
}
</style>
