<i18n>
  {
    "de": {
      "pageTitle": "Portfolios",
      "deletePendingMessage": "Portfolio wird gelöscht...",
      "noPortfolios": "Keine Portfolios",
      "addPortfolio": "Portfolio erstellen",
      "importPortfolio": "Portfolio importieren",
      "userViewLabel": "Benutzer-Ansicht",
      "deleteConfirmation": "Wollen Sie Portfolio \"{name}\" wirklich löschen",
      "idTitle": "ID",
      "nameTitle": "Name",
      "buildingCountTitle": "Liegenschaften",
      "plannerStatusCountTitle": "Planer",
      "reporterStatusCountTitle": "Reporter",
      "archivedStatusCountTitle": "Archiviert",
      "lastModifiedTitle": "Letzte Änderung",
      "stateFilterLabel": "Status",
      "stateFilterLabels": {
        "ALL": "Alle",
        "ACTIVE": "Aktiv",
        "INACTIVE": "Inaktiv"
      }
    }
  }
</i18n>

<template>
  <MainLayout :ready="allPortfoliosReady" class="p-admin-portfolios-page">
    <template #default>
      <h1>{{ $t('pageTitle') }}</h1>
      <ButtonWrapper>
        <router-link class="button" :to="{ name: 'adminAddPortfolio' }">{{ $t('addPortfolio') }}</router-link>
        <router-link class="button" :to="{ name: 'adminImportPortfolio' }">{{ $t('importPortfolio') }}</router-link>
      </ButtonWrapper>
      <Menu left class="state-filter-picker" :buttonText="stateFilterButtonText">
        <template #options>
          <MenuItem
            v-for="sf in stateFilterOptions"
            :id="`state-filter-${sf}`"
            :key="sf"
            :label="$t(`stateFilterLabels.${sf}`)"
            :type="'label'"
            :value="sf"
            @click="onStateFilterClick"
          />
        </template>
      </Menu>
      <p v-if="deleteError">{{ deleteError }}</p>
      <p v-if="deletePending">{{ $t('deletePendingMessage') }}</p>
      <DetailList v-else-if="portfolios.length > 0" has-header class="portfolios-list">
        <template #header>
          <span>{{ $t('idTitle') }}</span>
          <span>{{ $t('nameTitle') }}</span>
          <span class="align-right">{{ $t('buildingCountTitle') }}</span>
          <span class="align-right">{{ $t('plannerStatusCountTitle') }}</span>
          <span class="align-right">{{ $t('reporterStatusCountTitle') }}</span>
          <span class="align-right">{{ $t('archivedStatusCountTitle') }}</span>
          <span class="align-right">{{ $t('lastModifiedTitle') }}</span>
          <span class="align-right"></span>
        </template>
        <li v-for="portfolio in filteredPortfolios" :key="portfolio.id" :class="{ inactive: !isActive(portfolio) }">
          <span>{{ portfolio.id }}</span>
          <router-link
            :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } }"
            class="portfolio-title"
          >
            {{ portfolio.name }}
          </router-link>
          <span class="align-right">{{ portfolio.summary.total }}</span>
          <span class="align-right status-pill planner">{{ portfolio.summary.status_counts.planner }}</span>
          <span class="align-right status-pill reporter">{{ portfolio.summary.status_counts.reporter }}</span>
          <span class="align-right status-pill archived">{{ portfolio.summary.status_counts.archived }}</span>
          <span class="align-right">{{
            portfolio.last_modified
              | luxon({
                input: { format: 'http' },
                output: { format: 'full', locale: $i18n.locale },
              })
          }}</span>
          <ListButtonWrapper>
            <ListDeleteButton @click="onDeletePortfolio(portfolio)" />
          </ListButtonWrapper>
        </li>
      </DetailList>
      <p v-else>{{ $t('noPortfolios') }}</p>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import Menu from '@/components/shared/menu/Menu.vue'
import MenuItem from '@/components/shared/menu/MenuItem.vue'

export default {
  name: 'adminPortfolios',

  mixins: [
    AllPortfoliosMixin, // Provides: portfolios, allPortfoliosReady
  ],

  components: {
    ListButtonWrapper,
    ListDeleteButton,
    MainLayout,
    ButtonWrapper,
    Menu,
    MenuItem,
    DetailList,
  },

  data() {
    return {
      deletePending: false,
      deleteError: null,
      stateFilter: 'ALL',
      stateFilterOptions: ['ALL', 'ACTIVE', 'INACTIVE'],
    }
  },

  computed: {
    filteredPortfolios() {
      if (this.stateFilter === 'ALL') {
        return this.portfolios
      } else {
        return this.portfolios.filter((p) => this.isActive(p) === (this.stateFilter === 'ACTIVE'))
      }
    },

    stateFilterButtonText() {
      return `${this.$t('stateFilterLabel')}: ${this.$t(`stateFilterLabels.${this.stateFilter}`)}`
    },
  },

  methods: {
    ...mapActions({
      deletePortfolio: 'portfolio/deletePortfolio',
    }),

    async onDeletePortfolio(portfolio) {
      const confirmationText = this.$t('deleteConfirmation', { name: portfolio.name })
      if (confirm(confirmationText)) {
        try {
          this.deletePending = true
          await this.deletePortfolio(portfolio.id)
        } catch (error) {
          this.deleteError = error
        }
        this.deletePending = false
      }
    },

    isActive(portfolio) {
      if (portfolio.inactive_after === null) {
        return true
      } else {
        const inactive = new Date(Date.parse(portfolio.inactive_after))
        inactive.setHours(23, 59, 59, 999)
        return new Date() <= inactive
      }
    },

    onStateFilterClick(value) {
      this.stateFilter = value
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss">
.p-admin-portfolios-page {
  .detail-list > ul > li {
    display: grid;
    grid-template-columns: 60px 1.5fr 1fr 1fr 1fr 1fr 1.5fr 60px;

    & .align-right {
      text-align: right;
      justify-content: flex-end;
    }
  }
}
</style>

<style lang="scss" scoped>
.p-admin-portfolios-page {
  & .portfolio-title {
    font-weight: 600;
  }
  & .state-filter-picker {
    margin-bottom: var(--spacing-m);
    display: inline-block;
  }

  & .portfolios-list {
    & .status-pill {
      font-weight: 700;
      letter-spacing: 2px;
      margin-left: auto;
      padding: 0 var(--spacing-xs) 0 8px;
      border-radius: 13px;

      &.planner {
        color: #1a9900;
        background-color: #1a99001a;
      }

      &.reporter {
        color: #008099;
        background-color: #0080991a;
      }

      &.archived {
        color: #777777;
        background-color: #7777771a;
      }
    }

    li.inactive {
      > span,
      > a {
        opacity: 0.5;
      }
    }
  }
}
</style>
