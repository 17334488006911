<template>
  <li class="c-menu-item">
    <!-- Checkbox or radio options -->
    <template v-if="type !== 'label'">
      <label :for="elementId" class="input-option" :class="{ 'label-disabled': disabled }">
        <input
          :id="elementId"
          :type="type"
          :disabled="disabled"
          :checked="checked"
          :value="value"
          @change="onInputChange"
        />
        {{ label }}
      </label>
    </template>
    <!-- Label only, icon allowed -->
    <template v-else>
      <div class="label-option-wrapper" @click="onLabelClick">
        <div v-if="hasIconSlot" class="option-icon">
          <slot name="icon" class="icon-slot" />
        </div>
        <span>{{ label }}</span>
      </div>
    </template>
  </li>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    elementId() {
      return this.id || this.label.replace(/\s/g, '-').toLowerCase()
    },

    hasIconSlot() {
      return !!this.$slots.icon
    },
  },

  methods: {
    onInputChange(e) {
      this.$emit('change', this.value, e.target.checked)
    },

    onLabelClick(e) {
      this.$emit('click', this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-menu-item {
  display: flex;
  align-items: stretch;

  & .label-disabled {
    color: #ccc;
  }

  & .input-option {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    flex-grow: 1;
    padding: var(--box-padding);
    padding-right: var(--spacing-m);
  }

  & .label-option-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: var(--box-padding);
    padding-right: var(--spacing-m);

    & > span {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & .option-icon {
      flex-shrink: 0;
      margin-right: var(--spacing-xs);
    }
  }

  & > *,
  & > * > input {
    cursor: pointer;
  }
}
</style>
